/* Dropdowns */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "Material Design Icons";
      content: "\F140";  
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: .625rem;
      font-size: 1.25rem;
      margin-left: 0;
    }
  }
  .dropdown-menu {
    margin-top: 0.2rem;
    font-size: $default-font-size;
    box-shadow: 0px 1px 15px 1px rgba(112, 120, 137, 0.35);
    padding: 0;
    border:none;
    -webkit-box-shadow: 11px 10px 20px -18px rgba(255,255,255,0.39);
    -moz-box-shadow: 11px 10px 20px -18px rgba(255,255,255,0.39);
    box-shadow: 11px 10px 20px -18px rgba(255,255,255,0.39);
    border: $card-border-width solid $card-border-color;
    padding-top: 0;
    border-radius: 4px; 
    .dropdown-item {
      font-size: .875rem;
      padding: .65rem 1.5rem;
      &:hover{
        background: $white;
      }
      &:active {
        background: initial;
      }
    }
  }
}
.dropdown-menu {
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}
