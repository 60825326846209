/* Footer */
.footer-wrapper{
  background: $content-bg;
  padding: 0 2.188rem 2.188rem 2.188rem;
  width: 100%;
  @media(max-width: 767px) {
    padding: 1rem 1rem;
  }
  .footer {
    background: $footer-bg;
    color: $footer-color;
    padding: 20px 1rem;
    transition: all $action-transition-duration $action-transition-timing-function;
    -moz-transition: all $action-transition-duration $action-transition-timing-function;
    -webkit-transition: all $action-transition-duration $action-transition-timing-function;
    -ms-transition: all $action-transition-duration $action-transition-timing-function;
    font-size: 0.875rem;
    font-family: $type1;
    font-weight: 400;
    border-top: 1px solid rgba($black, .06);
    border-radius:6px;
    a {
      color: theme-color(primary);
      font-size: inherit;
    }
    @media (max-width: 991px) {
      margin-left: 0;
      width: 100%;
    }
  }
}
